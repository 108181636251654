import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Martins from './others/Martins.png';
import Footer from './general/Footer';
import TopNav from './general/TopNav';

const KnowMoreT = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      <div className="bg-white mt-36 p-6 max-w-5xl mx-auto flex flex-col sm:flex-row gap-6">
                             <Link
                          to="/about"
                          className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
                        >
                          <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
                        </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-8 flex flex-col items-center sm:w-1/3">
          <img
            src={Martins}
            alt="Tavershima Martins"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">Tavershima Martins</h2>
          <p className="mt-2 bg-blue-300 p-2 font-bold text-gray-800 text-center rounded-md">
            Co-Founder & COO
          </p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-12 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
                Tavershima Martins Ateh is a dynamic leader, entrepreneur, and advocate for Africa’s agricultural and 
                technological advancement. He is the Founder and CEO of Green Continental Limited, a pioneering 
                company at the forefront of Africa’s agricultural transformation. Green Continental is dedicated to 
                enhancing farming practices across the continent and ensuring food security.Tavershima is the Chairman of Proanalytica Limited, a data analytics company supporting 
                policy-making across Africa. 
              </p>
            </div>
            <div>
              <p className="text-gray-900">
                He also serves as the Executive Director at ASEAD, promoting 
                economic empowerment in underserved communities. His leadership in these roles has significantly 
                contributed to sustainable development. As Co-Founder & Co-Chair of Oracleous Enterprise, alongside Daniel Chukwu, Tavershima is 
                actively engaged in palm oil processing and supply chain management. His commitment to 
                sustainability, innovation, and rural development continues to drive economic and social impact.
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreT;
