import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Dami from "../../src/assets/others/Dami Sokale1.png";
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreO = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      <div className="bg-white mt-36 p-6 max-w-5xl mx-auto flex flex-col sm:flex-row gap-6">
                     <Link
                  to="/about"
                  className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
                >
                  <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
                </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center sm:w-1/3">
          <img
            src={Dami}
            alt="Daniel Chukwu"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">Oluwadimilola Sokale</h2>
          <p className="mt-2 bg-blue-300 p-2 font-bold text-gray-800 text-center rounded-md">AML Compliance Analyst</p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-12 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
              Oluwadamilola Sokale is a dedicated AML Compliance Analyst at Sterling Bank Plc, where she plays a critical role in ensuring adherence to anti-money laundering (AML) and combating the financing of terrorism (CFT) regulations. With a strong legal background and specialized expertise in financial compliance, she is committed to mitigating risks and upholding regulatory standards within the banking sector.
              She holds multiple professional certifications, including the Certified Anti-Money Laundering Specialist (CAMLS) from ACAMS and the Designate Compliance Professional certification from the Compliance Institute, Nigeria. These credentials underscore her deep knowledge of AML frameworks, financial crime prevention, and regulatory compliance.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
                Oluwadamilola Sokale is a dedicated AML Compliance Analyst at Sterling Bank Plc, where she plays a critical role in ensuring adherence to anti-money laundering (AML) and combating the financing of terrorism (CFT) regulations. With a strong legal background and specialized expertise in financial compliance, she is committed to mitigating risks and upholding regulatory standards within the banking sector.
                She holds multiple professional certifications, including the Certified Anti-Money Laundering Specialist (CAMLS) from ACAMS and the Designate Compliance Professional certification from the Compliance Institute, Nigeria. These credentials underscore her deep knowledge of AML frameworks, financial crime prevention, and regulatory compliance.
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreO;
