import React, { useState, useEffect, useCallback } from "react";
import { HomeCardData } from "../../data/HomeCardData";
import palm6 from "../../assets/home/palm6.jpg";
import palm12 from "../../assets/home/palm12.jpg";
import palm3 from "../../assets/home/palm.jpg";

function HomeHero() {
  const images = [palm12, palm6, palm3]; // Add more images if needed
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Change image every 4 seconds
    return () => clearInterval(interval);
  }, [nextImage]);

  return (
    <div className="mt-[75px] relative">
      {/* Background Image Carousel */}
      <div
        className="absolute inset-0 w-full h-[400px] md:h-[434px] lg:h-[386px] bg-no-repeat bg-cover bg-center object-cover transition-all duration-1000"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      />

      {/* Dark Overlay for Readability */}
      <div className="absolute inset-0 h-[400px] md:h-[434px] lg:h-[386px] bg-black/60" />

      {/* Hero Content */}
      <div className="relative px-[40px]">
        <h1 className="text-3xl md:text-5xl text-purple-50 pt-16 md:pt-[100px] lg:pt-[150px] lg:mr-[400px] font-sans font-bold">
          Introducing Nigeria's Most Affordable and Undiluted Palm Oil
        </h1>
        <div className="h-[5px] w-[310px] bg-lime-400"></div>
        <div className="flex justify-between mt-6 pb-10 mb-10 items-center">
          <p className="text-purple-50 md:w-3/5">
            Oracleous Enterprises is dedicated to producing Nigeria's most
            affordable and undiluted palm oil. By harnessing cutting-edge
            technology and sustainable practices, we ensure high-quality
            products that meet both local and global demand.
          </p>
        </div>
      </div>

      {/* Cards Section */}
      <div className="relative grid md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-[40px] mt-[50px] px-10 md:px-16">
        {HomeCardData.map((data, index) => (
          <div
            key={index}
            className="bg-lime-900 lg:h-[500px] p-4 text-center rounded-lg"
          >
            <h3 className="font-bold text-2xl text-purple-50 font-serif">
              {data.title}
            </h3>
            <img
              src={data.image}
              alt=""
              className="w-2/5 lg:w-[180px] lg:h-[180px] mx-auto md:mt-10"
            />
            <p className="text-purple-50 mt-[30px]">{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeHero;
