import React from "react";
import OurStoryHeroPic from "../../assets/others/ourStoryHeroPic.jpg";
import OurStoryPalm from "../../assets/others/ourStoryPalm.jpg";
import whyUsPalm from "../../assets/whyUs/whyUsPalm.jpg";

function OurStoryHero() {
  return (
    <div>
      <img
        src={OurStoryHeroPic}
        alt="A male farmer gathering produce in a farm"
        className="w-full h-[450px]"
      />

      <div className="h-[550/px] w-full bg-green-950 pt-1 pb-6 overflow-hidden px-6">
        <h1 className="font-bold text-4xl lg:text-[45px] text-center mt-8 text-white font-serif">
          Oracleous Enterprises
        </h1>
        <div className="max-w-screen-md mx-auto mt-8 space-y-6 md:text-center">
          <p className="text-white text-sm md:text-base">
            Oracleous enterprise is a mid-stream player in the oil palm value
            chain
            <br className="hidden md:block" />
            that focuses on the processing and distribution of palm oil.{" "}
            <br className="hidden md:block" />
            The focus of Oracleous enterprise stems from the obvious increase in
            the consumption <br className="hidden md:block" />
            of palm oil and the apparent growth in the value and demand for
            high-quality palm oil across Nigeria.
            <br className="hidden md:block" />
            Our target market includes both the palm oil retailers and corporate
            consumers in various parts of Nigeria and beyond.
          </p>
          <p className="text-white text-sm md:text-base">
            Oracleous enterprise is filling the demand and supply gap in the
            palm
            <br className="hidden md:block" />
            oil industry by aggregating the palm fruits from our network of
            farmers <br className="hidden md:block" />
            in Anambra and Edo state, processing the palm kernel to crude palm
            oil (red oil),
            <br className="hidden md:block" />
            so as to meet the demand for palm oil in Kaduna state, Nigeria.{" "}
            <br className="hidden md:block" />
            Our network of farmers are majorly located in Edo state. By
            aggregating <br className="hidden md:block" />
            palm fruits from them we are able to currently produce 144,000
            liters of palm <br className="hidden md:block" />
            oil per harvest season. The harvest season is between November to
            June
            <br className="hidden md:block" />
            The farmers also get to generate decent income from the sales of the
            palm fruits.
            <br className="hidden md:block" />
          </p>
        </div>
      </div>

      <div className="bg-green-950 pt-18 px-4 pb-10">
        <div className="flex flex-col md:flex-row text-white py-6 justify-center gap-8">
          <p className="md:text-center text-sm md:text-base">
            In a quaint village nestled among lush green palm trees,
            <br className="hidden md:block" /> Oracleous Enterprises began as a
            humble household business.
            <br className="hidden md:block" /> It all started when a determined
            woman,
            <br className="hidden md:block" />
            fueled by a passion for agriculture, noticed her community’s
            <br className="hidden md:block" /> reliance on a popular cooking
            oil. She decided to take action,
            <br className="hidden md:block" /> using traditional methods passed
            down through generations to create{" "}
            <br className="hidden md:block" /> small batches by hand.
          </p>
          <p className="md:text-center text-sm md:text-base">
            Every morning, she would rise with the sun, gathering fresh fruits
            from <br className="hidden md:block" />
            towering trees, boiling them in large pots, and manually{" "}
            <br className="hidden md:block" />
            pressing them to extract the golden liquid.
            <br className="hidden md:block" /> Family and friends often joined,
            turning the labor into a joyful community affair.{" "}
            <br className="hidden md:block" />
            The inviting aroma spread through the air, attracting neighbors
            eager to buy
            <br className="hidden md:block" /> the freshly made product. Word
            spread quickly about the quality, <br className="hidden md:block" />
            and soon, demand began to outstrip the manual capabilities.
          </p>
        </div>
        <div className="h-[8px] w-[250px] mt-6 bg-lime-700 mx-auto"></div>
      </div>

      <div className="bg-purple-200 py-16 w-full mx-auto ">
        <div className="bg-purple-100 w-full max-w-[1130px] pb-16 px-10 mx-auto">
          <h2 className="text-3xl pt-12 pb-6 md:text-center font-bold font-serif">
            Recognizing the potential for growth, our founding fathers pooled
            their resources, and took a bold step.
          </h2>

          <div className="flex flex-col gap-10 md:flex-row">
            <div className="w-full text-center">
              <h3 className="text-8xl font-bold font-serif">58</h3>
              <p className="mt-[20px]">
                Years of
                <br /> Experience
              </p>
            </div>

            <div className="w-full">
              <p className="first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700">
                They invested in semi-automated machinery, including a screw
                presser and a digester. This new equipment transformed the
                operation, allowing for larger quantities to be produced while
                maintaining the same high standards. With a small team, they
                worked tirelessly, expanding capacity and reaching out to new
                markets.
              </p>
            </div>
            <div className="w-full">
              <p className="md:mt-6">
                As their venture flourished, they also acquired a generator to
                ensure a steady power supply,
                <br /> enabling efficient operation of the machines. <br />
                Distribution expanded beyond the village, <br />
                securing contracts with local retailers and larger consumers.{" "}
                <br />
              </p>
            </div>
          </div>
        </div>

        <img
          src={OurStoryPalm}
          alt=""
          className="w-[1130px] mx-auto h-[450px]"
        />

        <div className="bg-purple-100 w-full px-10 max-w-[1130px] pb-16 mx-auto">
          <h3 className="text-3xl pt-14 md:text-center mb-10 font-bold font-serif">
            With each passing year, the initial vision transformed into a
            thriving enterprise. They became known not just for their product,
            but for a commitment to sustainable practices and community
            empowerment.
          </h3>

          <div className="flex flex-col gap-10 md:flex-row">
            <div className="w-full">
              <p
                style={{ initialLetter: 2 }}
                className="first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700 first-letter:px-2"
              >
                As the years went by, the impact of their efforts extended far
                beyond mere production. Our Fathers actively engaged with the
                community, offering training and workshops to local farmers on
                sustainable practices and modern techniques. This initiative not
                only empowered their neighbors but also fostered a sense of
                unity and collaboration. The once-small household endeavor
                blossomed into a movement, inspiring others to embrace
                innovation while honoring traditional methods. Today, their
                legacy is not just measured in the volume of oil produced, but
                in the vibrant community that has grown around them, all
                thriving together in harmony with nature.
              </p>
            </div>
            <div className="w-full">
              <p
                style={{ initialLetter: 2 }}
                className="first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700 first-letter:px-2"
              >
                Today, this venture stands as a beacon of innovation and
                resilience, illustrating how a small household endeavor can grow
                into a significant force in the agricultural landscape. The
                founders often reflect on their journey with gratitude, knowing
                that their story is woven into the very fabric of their
                community. From manual labor to modern machinery, they continue
                to thrive, driven by the same passion and dedication that
                sparked their beginnings.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-10 md:px-16 mt-10">
        <div className="flex flex-col md:flex-row md:justify-center gap">
          <div className="flex flex-col justify-center bg-green-950 lg:h-[450px] px-6 pb-6 md:px-10 md:w-[700px]">
            <h2 className="font-bold text-[28px] py-6 md:pt-[12/0px] text-purple-200 font-serif">
              African Oil Palm: Bridging Tradition and Modernity
            </h2>
            <p className="text-slate-200 text-[17px]">
              African oil palm is more widely distributed than other types of
              palm trees and was first discovered in Africa along the coastal
              strip. Since being domesticated, the oil palm has been imported
              and grown throughout the humid tropics, with the majority of palm
              oil being used for food. It still has numerous traditional
              applications in Africa. Breeding can increase the amount of oil
              produced by the palm, enhance the type of oil it produces, and
              improve nutritional value.
            </p>
          </div>
          <div className="order-first md:order-last">
            <img
              src={whyUsPalm}
              alt="hands picking up fresh palm fruit"
              className="w-full h-full lg:h-[450px] md:w-[450px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStoryHero;
