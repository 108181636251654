import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Eze from "../../src/assets/others/eze.png";
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreE = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      
      {/* <Link to="/our-story"></Link> */}
      <div className="bg-white mt-36 p-4 max-w-6xl mx-auto flex flex-col sm:flex-row gap-6">
              <Link
          to="/about"
          className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
        >
          <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
        </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center sm:w-1/3">
          <img
            src={Eze}
            alt="Daniel Chukwu"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">EZE NWAKANMA</h2>
          <p className="mt-2 bg-blue-300 p-2 font-bold text-gray-800 text-center rounded-md">HCIB</p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-6 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
              Eze Nwakanma is a highly accomplished banking and financial expert with over nineteen years of experience spanning agricultural financing, banking operations, financial advisory services, international banking, and business development. His career has been defined by a deep commitment to driving financial growth, investment, and risk management, particularly within the agribusiness sector. With extensive experience working both locally and internationally, including in The Gambia and Senegal, he has played a significant role in shaping financial strategies that promote sustainable agricultural and export financing.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
              Currently serving as the Head of Agribusiness & Export Operations at Impact Credit Guarantee Ltd, Eze is at the forefront of creating innovative financial solutions that enable increased credit accessibility for agribusinesses while mitigating risks. His leadership ensures the development of strategic partnerships that strengthen credit guarantee systems, facilitating sustainable investments in the sector.
              Previously, he was the Head of Agribusiness & Non-Oil Export at Union Bank of Nigeria, where he played a pivotal role in expanding access to financing for agricultural businesses. During his tenure as Head of Agric Value Chain Finance & Investment Services at NIRSAL Plc from 2018 to 2021, he was responsible for increasing the flow of finance from development finance institutions, commercial banks, insurance firms, and investment organizations into the agricultural sector. His ability to leverage his extensive banking experience and relationships significantly enhanced funding opportunities across all segments of the agricultural value chain, from the pre-upstream to the downstream sectors. His leadership contributed to the success of NIRSAL, Nigeria’s most capitalized risk-sharing facility, ensuring that agribusinesses had the financial support needed to thrive.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
              In addition to his leadership roles in banking and agribusiness finance, Eze also served as Deputy Chief of Party at Cultivating New Frontiers in Agriculture (CNFA) in 2021, where he was involved in high-level strategic initiatives aimed at boosting agricultural efficiency and financial sustainability.
               With a strong background in risk management, business development, investment strategies, and international banking, Eze Nwakanma is a results-driven professional dedicated to transforming the agribusiness landscape through financial innovation. His expertise in structuring financial models that support economic growth and sustainable investment continues to make a significant impact in the industry.
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreE;
