import React from "react";
import ImageSlider from "../../data/ImageSlider";
import RedPalm from "../../assets/ourExperience/Red-Palm-.jpg";
import PalmAgr from "../../assets/ourExperience/PalmAgr.jpg";
import shutter from "../../assets/ourExperience/shutterstock.webp";
import white from "../../assets/ourExperience/whitePalm.jpg";
import PalmPeople from "../../assets/ourExperience/PalmPeople.jpg";
import PalmHand from "../../assets/ourExperience/pamlHand.jpg";

function OurExperiences() {
  const slides = [
    {
      image : RedPalm,
      alt:"fresh palm kernels beside a cup of palm oil"
    },
    {
      image : PalmAgr,
      alt:"two men with cart harvesting palm produce"
    },
    {
      image : shutter,
      alt:"fresh palm in a basket beside a nice extract"
    },
    {
      image : white ,
      alt:"fresh palm in a basket beside a nice extract"
    },
    {
      image :PalmPeople,
      alt:"farmers returning from the farm with palm produce on their head"
    },
    {
      image : PalmHand,
      alt:"hand holding a freshly plucked palm kernel"
    }
  ];

  return (
<div className="flex justify-center mt-12 px-4">
  <div className="bg-white shadow-lg rounded-2xl p-6 max-w-4xl w-full flex flex-col sm:flex-row items-center sm:items-start gap-6">
    
    {/* Image Slider - Proper Positioning for Mobile & Desktop */}
    <div className="w-full sm:w-1/2 h-[340px] flex justify-center order-1 mb-[110px] sm:order-none sm:relative sm:-top-3 mb-4 sm:mb-0">
      <ImageSlider slides={slides} />
    </div>

    {/* Text Content - Stays Below Slider on Mobile */}
    <div className="w-full sm:w-1/2 animate-fade-in text-center sm:text-left">
    <h1 className="text-3xl font-bold text-gray-800">Oracleous Enterpises</h1>
    <br />
      <p className="text-l text-gray-800">
      Oracleous Enterprises tackles palm oil processing inefficiencies, farmer financial exclusion, and environmental waste through technology-driven solutions.
       We provide semi-automated processing facilities, ensuring higher efficiency, reduced post-harvest losses, and premium-quality palm oil. 
      Farmers receive fair trade pricing, market access, and training in regenerative agriculture. 
      </p>
      <br/>
      <p className="text-ltext-gray-800">
      Our waste-to-energy model converts palm oil by-products into biochar and biogas, reducing CO₂ emissions while creating new revenue streams.
       Through carbon credit monetization, digital trade platforms, and sustainable supply chain integration, we make palm oil production more profitable,
        eco-friendly, and globally competitive.
      </p>
    </div>
  </div>
</div>

    
  );
}

export default OurExperiences;
