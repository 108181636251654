import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ProductPage from "./pages/ProductPage";
import KnowMoreT from "./pages/KnowMoreT";
import KnowMoreS from "./pages/KnowMoreS";
import KnowMoreD from "./pages/KnowMoreD";
import KnowMoreE from "./pages/KnowMoreE";
import KnowMoreO from "./pages/KnowMoreO";
import KnowMoreA from "./pages/KnowMoreA";
import ContactUsPage from "./pages/ContactUsPage";
import OurStoryPage from "./pages/OurStoryPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/KnowMoreT" element={<KnowMoreT />} />
        <Route path="/KnowMoreS" element={<KnowMoreS />} />
        <Route path="/KnowMoreD" element={<KnowMoreD />} />
        <Route path="/KnowMoreE" element={<KnowMoreE />} />
        <Route path="/KnowMoreO" element={<KnowMoreO />} />
        <Route path="/KnowMoreA" element={<KnowMoreA />} />
        <Route path="/our-story" element={<OurStoryPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
