
import React, { useState, useEffect, useCallback } from "react";

import arrowL from "../assets/icons/a-l.png";

import arrowR from "../assets/icons/a-r.png";
 
function ImageSlider({ slides }) {

  const [currentIndex, setCurrentIndex] = useState(0);
 
  const goToNext = useCallback(() => {

    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));

  }, [slides.length]);
 
  useEffect(() => {

    const intervalId = setInterval(() => {

      goToNext();

    }, 3000); // Auto-slide every 3 seconds
 
    return () => clearInterval(intervalId);

  }, [goToNext]);
 
  const goToPrevious = () => {

    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));

  };
 
  const goToSlide = (slideIndex) => {

    setCurrentIndex(slideIndex);

  };
 
  return (
<div className="relative w-full h-full">

      {/* Image Slide */}
<div

        className="w-full h-full rounded-lg bg-center bg-cover mt-[70px] transition-all duration-500"

        style={{ backgroundImage: `url(${slides[currentIndex].image})` }}
></div>
 
      {/* Navigation Arrows */}
<button

        className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white p-2 bg-black/30 rounded-full hover:bg-black/70 transition"

        onClick={goToPrevious}
>
<img src={arrowL} alt="Previous" className="w-8 h-8" />
</button>
 
      <button

        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white p-2 bg-black/30 rounded-full hover:bg-black/70 transition"

        onClick={goToNext}
>
<img src={arrowR} alt="Next" className="w-8 h-8" />
</button>
 
      {/* Dots for navigation */}
<div className="flex justify-center mt-4 space-x-2">

        {slides.map((_, slideIndex) => (
<input

            key={slideIndex}

            type="radio"

            name="slideSelector"

            className="w-4 h-4 accent-green-500 cursor-pointer"

            checked={slideIndex === currentIndex}

            onChange={() => goToSlide(slideIndex)}

          />

        ))}
</div>
</div>

  );

}
 
export default ImageSlider;

 
