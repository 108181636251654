import React, { useState } from "react";
import OracleousLogo2 from "../../assets/logo/OracleousLogo.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import closeIcon from "../../assets/icons/close.png";
import menuIcon from "../../assets/icons/menu.png";
import { Link } from "react-router-dom";

function TopNav({ location }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };
  console.log(location);

  const links = [
    { name: "Home", route: "/" },
    { name: "Product", route: "/product" },
    { name: "Our story", route: "/our-story" },
    { name: "About us", route: "/about" },
    { name: "Contact", route: "/contact" },
  ];
  return (
    <>
      <div className="Topnav w-full max-w-[1440px] mx-auto flex justify-between bg-white fixed top-0 z-20 h-[75px] shadow-md shadow-black">
        <Link to="/" className="Logo">
          <img
            src={OracleousLogo2}
            alt="Oracleous logo"
            className="h-[65px] ml-[40px] mt-[5px] "
          />
        </Link>

        <div className="Home hidden md:flex space-x-[50px] pt-[30px] text-lg text-black pr-10">
          {links.map((link) => (
            <Link
              key={`${link.name}`}
              to={`${link.route}`}
              className={`${
                location === link.route ? "text-green-700 underline" : ""
              } hover:text-green-700 hover:underline`}
            >
              {link.name}
            </Link>
          ))}
        </div>
        <button
          className="block p-1 mr-10 w-10 md:hidden"
          onClick={handleNavOpen}
        >
          <img src={menuIcon} alt="menu icon" className="w-full" />
        </button>
      </div>

      {/* Mobile nav */}
      <Dialog as="div" open={isNavOpen} onClose={handleNavOpen}>
        <div className="fixed inset-0 z-50 bg-black/80" />
        <DialogPanel className="fixed inset-y-0 pl-10 right-0 z-50 w-3/6 md:w-[550px] bg-white">
          <div className="flex justify-between pr-10">
            <div />
            <button
              className="block p-1 w-7 mt-[30px] shadow-lg hover:shadow-xl"
              onClick={handleNavOpen}
            >
              <img src={closeIcon} alt="menu icon" className="w-full" />
            </button>
          </div>
          <div className="flex flex-col gap-10 pt-[30px] text-2xl text-black">
            {links.map((link) => (
              <Link
                key={`${link.name}`}
                to={`${link.route}`}
                className={`${
                  location === link.route ? "text-green-700 underline" : ""
                } hover:text-green-700 hover:underline`}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
}

export default TopNav;
