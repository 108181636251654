import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Amaka from "../../src/assets/others/Amaka1.png";
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreA = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      <div className="bg-white mt-36 p-6 max-w-5xl mx-auto flex flex-col sm:flex-row gap-6">
                     <Link
                  to="/about"
                  className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
                >
                  <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
                </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center sm:w-1/3">
          <img
            src={Amaka}
            alt="Daniel Chukwu"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">AMAKA ONUH</h2>
          <p className="mt-2 bg-blue-300 p-2 font-bold text-gray-800 text-center rounded-md">Deputy Chief Accountant</p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-12 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
              Amaka Onuh is an experienced accounting professional currently serving as the Deputy Chief Accountant at Alpha Pharmacy. With a strong background in financial management and accounting, she plays a crucial role in overseeing financial reporting, budgeting, and ensuring compliance with accounting standards.
                Her expertise extends to the use of modern financial tools, as she holds a QuickBooks Online Certification, demonstrating her proficiency in cloud-based accounting solutions for efficient financial management. Her knowledge of accounting principles, coupled with practical experience in financial operations, makes her a valuable asset in driving financial accuracy and operational efficiency within the organization.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
              Amaka is dedicated to maintaining strong financial controls and optimizing accounting processes, contributing to the overall financial health of Alpha Pharmacy.
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreA;
