import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Daniel from './others/Daniel.jpg';
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreD = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      <div className="bg-white mt-36 p-6 max-w-5xl mx-auto flex flex-col sm:flex-row gap-6">
                             <Link
                          to="/about"
                          className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
                        >
                          <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
                        </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center sm:w-1/3">
          <img
            src={Daniel}
            alt="Daniel Chukwu"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">Daniel Chukwu</h2>
          <p className="mt-2 bg-blue-300 p-2 font-bold text-gray-800 text-center rounded-md">Founder & CEO</p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-12 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
                Daniel Chukwu is a visionary entrepreneur, social innovator, and expert in security and strategic
                leadership. He is the Founder and CEO of Oracleous Enterprise, a leading palm oil processing
                company based in Abuja, Nigeria.With a diverse background that spans business, security, and social development, Daniel has built a 
                reputation as a leader who combines business acumen with a deep commitment to sustainability and 
                community empowerment.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
                Daniel has been pivotal in driving Oracleous Enterprise’s strategic vision, positioning it as a key 
                player in the palm oil industry. His leadership includes fostering strong partnerships, implementing 
                sustainable practices, and promoting social responsibility. Beyond his business ventures, Daniel is actively engaged in community service and social 
                innovation. He mentors youth, facilitates leadership workshops, and contributes to peacebuilding 
                initiatives across different communities.Recognized for his leadership, Daniel has received fellowships like the LEAP Africa Social 
                Innovation Program Fellowship and The Platform Public Service Fellowship, solidifying his role as a 
                rising leader.
              </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreD;
