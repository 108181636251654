import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Samuel from './others/Samuel.jpg';
import TopNav from './general/TopNav';
import Footer from './general/Footer';

const KnowMoreS = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <>
      <TopNav />
      <div className="bg-white mt-36 p-6 max-w-5xl mx-auto flex flex-col sm:flex-row gap-6">
                             <Link
                          to="/about"
                          className="flex items-center justify-center text-white h-[40px] w-[150px] bg-lime-600 hover:bg-lime-500 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 mx-auto my-4"
                        >
                          <span className="text-sm md:text-xs p-2 font-semibold">Go Back</span>
                        </Link>
        {/* Left Card: Name & Title */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center sm:w-1/3">
          <img
            src={Samuel}
            alt="Nwachukwu Samuel"
            className="w-40 h-40 rounded-full object-cover sm:w-48 sm:h-48"
          />
          <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">Nwachukwu Samuel</h2>
          <p className="mt-2 bg-blue-500 p-2 font-bold text-white text-center rounded-md">CTO & CFO</p>
        </div>

        {/* Right Card: Slider */}
        <div className="bg-white shadow-lg rounded-lg p-12 sm:w-2/3">
          <Slider {...settings}>
            <div>
              <p className="text-gray-900">
              Nwachukwu Samuel Chukwuemeka is a seasoned finance professional and software engineer with a strong background in financial management, auditing, tax administration, and business development. With a deep passion for technology, he has successfully merged his finance expertise with software engineering, specializing in secure transaction systems, financial data security, and API development for financial applications.
              Mr Nwachukwu is highly skilled in leveraging programming languages like Java, Python, and JavaScript, and modern frameworks such as Spring Boot, Django, and React.js. His expertise extends to database management using MySQL, PostgreSQL, and MongoDB, with proficiency in Agile methodologies and Test-Driven Development. He has experience developing RESTful APIs, enhancing cybersecurity protocols, and deploying scalable software solutions for financial systems.
              His experience spans across finance and software engineering, currently serving as the Chief Finance Officer (CFO) of Oracleous Enterprises and having worked as an Account Receivable Specialist, Finance Manager, Internal Auditor, Software Engineer, and Data Analyst. He possesses hands-on experience in business development, problem-solving, and data analytics, making him a versatile professional.
              He holds a B.Sc. in Economics from Federal University Dutsinma, Katsina State, graduating with a Second-Class Upper in 2018.
              </p>
            </div>
            <div>
              <p className="text-gray-900">
                He also obtained a Certificate in Data Science and Software Engineering from Semicolon Africa in 2023. Additional certifications include Project Management from the Management & Strategy Institute, Cash Flow Management from Alison Online Courses, and Understanding Renewable Energy from YALI Network.
                He has worked as a Software Engineer Intern at SpringCore Africa, where he developed RESTful APIs with Spring Boot and React.js, implemented Spring Security to safeguard financial data, and adopted Agile and Test-Driven Development methodologies. During his training at Semicolon Africa, he built an e-Trust application for secure digital transactions, developed real-time chat applications with media-sharing capabilities, and created an e-commerce platform for musical instruments.
                In the finance sector, he has worked as an Account Receivable Specialist at Puroclean of Marysville, USA, where he maintains billing systems, generates invoices, and manages customer accounts, collections, and payments. As a Data Analyst at Oracleous Enterprises, he analyzes financial and operational data to support decision-making and develops financial reporting systems. As Finance Manager at Hinata Energy, he handled tax administration, payroll processing, and cash reconciliations while conducting financial reporting and inventory analysis.</p>
            </div>
            <div>
              <p className="text-gray-900"> 
              As an Internal Auditor at Lace Restaurant & Bakery, he implemented financial controls, conducted risk analysis, and enhanced reporting structures to improve financial transparency.
              He has been involved in projects such as Cultify, an investment platform for agriculture that provides a secure system for funding farm projects using Spring Boot and OAuth2. He developed the SpringCore Africa Website, leading the end-to-end development of the organization's web presence. He worked on a Library Management System built with Django to manage library assets efficiently and an e-Wallet Application designed to facilitate secure transactions between users.
                He is a member of the World Economics Association, the African Finance and Economics Association, the Institute of Economic Development, and the International Society for Development and Sustainability. Mr Nwachukwu Samuel is committed to continuous learning, excellence, and leadership, striving to merge financial expertise with cutting-edge technology to drive innovation in the fintech industry. His unique blend of skills in finance and software engineering positions him as a valuable asset in any organization looking to optimize financial operations through technology.
                       </p>
            </div>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KnowMoreS;


